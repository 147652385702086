import React, { useEffect, useState } from "react";
import VideoPlaylist from "./VideoPlaylist";
import Footer from "./Footer";
import LatestNews from "./LatestNews";
import AwardsSection from "./AwardsSection";
import MayorSection from "./MayorSection";
import { AnimatePresence, motion } from "framer-motion";
import Navbar from "./Navbar";

import header from "../images/header-1.png";
import background_pic from "../images/nabunturan.jpg";
import heart_of_the_valley from "../images/theheartofthevalley.jpg";
import lgu_pic from "../images/municipyo2.jpg";
import InvitationToBid from "./InvitationToBid";
const images = [background_pic, heart_of_the_valley, lgu_pic];

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const videosList = [
    {
      video: "/NabunturanHymn.mp4",
      title: "Nabunturan Hymn",
    },
    {
      video: "/simballay_video.mp4",
      title: "27th Simballay Festival",
    },
    {
      video: "/Tuyawan.mp4",
      title: "Tuyawan Eco-Aqua Park",
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000); // Change the interval duration (in milliseconds) as needed

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);
  return (
    <div className="home">
      {/* NAVBAR */}
      <section id="Homepage">
        {/* HEADER */}
        <div className="header__container">
          <img src={header} alt="" />
        </div>
        <div className="background__container">
          {/* <img src={background_pic} alt="" /> */}

          <AnimatePresence>
            <motion.img
              key={currentIndex}
              src={images[currentIndex]}
              alt={`Image ${currentIndex + 1}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
          </AnimatePresence>
        </div>
      </section>
      {/* Mayor */}
      <section>
        <MayorSection />
      </section>
      {/* Awards */}
      <section>
        <AwardsSection />
      </section>

      <section>
        <LatestNews />
      </section>

      {/*Video Playlist */}

      <VideoPlaylist videosList={videosList} />
      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Home;
