import React from "react";
import header from "../images/header-1.png";
import "./Accounting.scss";
import VissionMissionGoalCard from "./VissionMissionGoalCard.js";
import Table from "./Table.js";
import data_sept3023 from "../data/accounting/data_sept3023.json";
import data_dec3123 from "../data/accounting/data_dec3123.json";
import data_0624 from "../data/accounting/data_0624.json";

function Accounting() {
  return (
    <div className="accounting">
      <div className="accounting_header_container">
        <img className="accounting_img" src={header} alt="" />
      </div>
      <div className="accounting_container">
        <div className="accounting_vmg_container">
          <VissionMissionGoalCard
            title="Vision"
            text="To submit accurate, reliable and timely Financial Reports that will be useful in the decision making and transparent for the public to keep track of the LGU's programs and projects and in strict adherence to existing laws, rules set by the governing body"
          />
          <VissionMissionGoalCard
            title="Mission"
            text="To submit accurate, reliable and timely Financial Reports that will be useful in the decision making and transparent for the public to keep track of the LGU's programs and projects and in strict adherence to existing laws, rules set by the governing body"
          />
        </div>
        <br />
        <div className="accounting_tableContainer">
          <h1 className="accounting_h1">local government support fund</h1>
          <p className="accounting_p">
            report on fund utilization and status of program/project
            implementation
          </p>
          <span className="accounting_span">
            For the Quarter Ended September 30, 2023
          </span>
          <Table data={data_sept3023} />
          <span className="accounting_span">
            For the Quarter Ended December 31, 2023
          </span>
          <Table data={data_dec3123} />
          <span className="accounting_span">
            For the Quarter Ended June 30, 2024
          </span>
          <Table data={data_0624} />
          <span className="accounting_span">
            For the Quarter Ended September 30, 2024
          </span>
          <Table data={data_0624} />
        </div>
      </div>
    </div>
  );
}

export default Accounting;
