// Footer.js
import React from "react";
import "./Footer.scss";
import { FaFacebook } from "react-icons/fa";
import nab_logo from "../images/logo_nab.png";
import serbisyo_uban_sa_gugma from "../images/ugban_sa_gugma.png";

const Footer = () => {
  return (
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="footer-col">
            <h4>Republic of the Philippines</h4>
            <ul>
              <li>
                <a href="#">
                  All content is in the public domain unless otherwise stated.
                </a>
              </li>
            </ul>
          </div>
          <div class="footer-col">
            <h4>About GOVPH</h4>
            <ul>
              <li>
                <a href="#">
                  Learn more about the Philippine government, its structure, how
                  government works and the people behind it.
                </a>
              </li>
              <li>
                <a href="#">GOV.PH</a>
              </li>
              <li>
                <a href="#">OPEN DATA PORTAL</a>
              </li>
              <li>
                <a href="#">OFFICIAL GAZETTE</a>
              </li>
            </ul>
          </div>
          <div class="footer-col">
            <h4>GOVERNMENT LINKS</h4>
            <ul>
              <li>
                <a href="#">Office of the President</a>
              </li>
              <li>
                <a href="#">Office of the Vice President</a>
              </li>
              <li>
                <a href="#">Senate of the Philippines</a>
              </li>
              <li>
                <a href="#">House of Representatives</a>
              </li>
              <li>
                <a href="#">Supreme Court</a>
              </li>
              <li>
                <a href="#">Court of Appeals</a>
              </li>
              <li>
                <a href="#">Sandigan Bayan</a>
              </li>
            </ul>
          </div>
          <div class="footer-col">
            <h4>follow us</h4>
            <div class="social-links">
              <a
                href="https://www.facebook.com/nabunturanlgu"
                target="_blank"
                rel="noreferrer"
              >
                {/* <i class="fab fa-facebook-f"></i> */}
                <FaFacebook />
              </a>
            </div>
          </div>
          <div class="footer-col">
            <img src={nab_logo} alt="" />
            <img src={serbisyo_uban_sa_gugma} className="serbisyo_img" alt="" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
