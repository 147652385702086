import React from "react";
import "./VissionMissionGoalCard.scss";

function VissionMissionGoalCard({ title, text }) {
  return (
    <div class="vmg__card">
      <a className="vmg__card1" href="#">
        <h3 className="vmg__h3">{title}</h3>
        <p className="vmg__p">{text}</p>
        <div className="vmg__go-corner" href="#">
          <div className="vmg__go-arrow">→</div>
        </div>
      </a>
    </div>
  );
}

export default VissionMissionGoalCard;
