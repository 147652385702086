import React from "react";
import "./Directory.scss";
import header from "../images/header-1.png";
import DirectoryCard from "./DirectoryCard";
import Footer from "./Footer";

function Directory() {
  return (
    <div className="directory">
      <div className="directory__header_container">
        <img className="directory__img" src={header} alt="" />
      </div>
      <div className="directory__container">
        <h1 className="directory__h1">Contact Numbers</h1>
        <div className="directory__offices_container">
          <DirectoryCard title="LGU Hotline Number" text="0938-031-2014" />
          <DirectoryCard
            title="Nabunturan Emergency Response Team"
            text="0946-246-1787/0917-130-3345"
          />
          <DirectoryCard title="Nabunturan Fire Station" text="0912-710-8980" />
        </div>{" "}
        <br />
        <div className="directory__offices_container">
          <DirectoryCard title="Human Resource" text="0916-967-2108" />
          <DirectoryCard title="MSWDO" text="0961-212-6143" />
          <DirectoryCard title="LGU Engineering Office" text="0938-377-8868" />
        </div>{" "}
        <br />
        <div className="directory__offices_container">
          <DirectoryCard title="SB Office" text="0929-667-9080" />
          <DirectoryCard
            title="Municipal Civil Registrar Office"
            text="0917-160-1351/0917-147-7409"
          />
          <DirectoryCard
            title="Municipal Planning and Development Office"
            text="0963-238-6639"
          />
        </div>{" "}
        <br />
        <div className="directory__offices_container">
          <DirectoryCard
            title="Nabunturan Police Station"
            text="0907-205-6499"
          />
        </div>
      </div>
      <br />
      <Footer />
    </div>
  );
}

export default Directory;
