import React from "react";
import "./LGUOffices.scss";
import header from "../images/header-1.png";
import Footer from "./Footer";
import { FaEarthAmericas } from "react-icons/fa6";
import { MdCalculate } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { MdRealEstateAgent } from "react-icons/md";
import { FaHospital } from "react-icons/fa";
import { MdAgriculture } from "react-icons/md";
import { GiArchiveRegister } from "react-icons/gi";
import { FaConnectdevelop } from "react-icons/fa";
import { GrResources } from "react-icons/gr";
import { MdChildCare } from "react-icons/md";
import { MdAddBusiness } from "react-icons/md";
import { MdAccountBalanceWallet } from "react-icons/md";
import { Link } from "react-router-dom";

function LGUOffices() {
  return (
    <div className="LGUOffices">
      <div className="LGUOffices__header_container">
        <img className="LGUOffices__img" src={header} alt="" />
      </div>
      <div className="LGUOffices__container">
        <h1 className="LGUOffices__h1">All Department Office</h1>
        <div className="LGUOffices__offices_container">
          <Link to="/budget-office">
            <div className="LGUOffices__box_container">
              <MdCalculate className="icon" />
              <span>Municipal Budget Office</span>
            </div>
          </Link>
          <Link to="/treasury-office">
            <div className="LGUOffices__box_container">
              <GiReceiveMoney className="icon" />
              <span>Municipal Treasurer Office</span>
            </div>
          </Link>
          <Link to="/assesor-office">
            <div className="LGUOffices__box_container">
              <MdRealEstateAgent className="icon" />
              <span>Municipal Assesor Office</span>
            </div>
          </Link>
          <Link to="/health-office">
            <div className="LGUOffices__box_container">
              <FaHospital className="icon" />
              <span>Municipal Health Office</span>
            </div>
          </Link>
          <Link to="/agriculture-office">
            <div className="LGUOffices__box_container">
              <MdAgriculture className="icon" />
              <span>Municipal Agriculture Office</span>
            </div>
          </Link>
        </div>
        <br />
        <div className="LGUOffices__offices_container">
          <Link to="/registrar-office">
            <div className="LGUOffices__box_container">
              <GiArchiveRegister className="icon" />
              <span>Municipal Civil Registrar Office</span>
            </div>
          </Link>
          <Link to="/development-office">
            <div className="LGUOffices__box_container">
              <FaConnectdevelop className="icon" />
              <span>Municipal Planning and Development Office</span>
            </div>
          </Link>
          <Link to="/hr-office">
            <div className="LGUOffices__box_container">
              <GrResources className="icon" />
              <span>Municipal Human Resource Management Office</span>
            </div>
          </Link>
          <Link to="/mswd-office">
            <div className="LGUOffices__box_container">
              <MdChildCare className="icon" />
              <span>Municipal Social Welfare and Development Office</span>
            </div>
          </Link>
          <Link to="/bplo-office">
            <div className="LGUOffices__box_container">
              <MdAddBusiness className="icon" />
              <span>
                Municipal Business Permit and Local Economic Enterprise Section
              </span>
            </div>
          </Link>
        </div>
        <br />
        <div className="LGUOffices__offices_container">
          <Link to="/accounting">
            <div className="LGUOffices__box_container">
              <MdAccountBalanceWallet className="icon" />

              <span>Municipal Accounting Office</span>
            </div>
          </Link>
        </div>
      </div>
      <br />
      <Footer />
    </div>
  );
}

export default LGUOffices;
