import React, { useState, useEffect } from "react";
import "./BacLoginButton.scss"; // Import your CSS file for styling
import { CiLogin } from "react-icons/ci";
import { Link, useLocation } from "react-router-dom";

const BacLoginButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  // Show the button when the user scrolls down
  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {location.pathname === "/bids-and-awards" && (
        <div className="bacLoginButton">
          {isVisible && (
            <Link to="/bac-login">
              <button className="bac-login-button">
                <CiLogin className="icon" />
              </button>
            </Link>
          )}
        </div>
      )}
    </>
  );
};

export default BacLoginButton;
