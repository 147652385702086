import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ViewNews.scss";
import header from "../images/header-1.png";
import newsData from "../news.json";
import Footer from "./Footer.js";

function ViewNews() {
  let { id } = useParams();
  const [newsDatas, setNewsDatas] = useState([]);
  useEffect(() => {
    newsData.map((news) => {
      setNewsDatas(news);
    });
  }, []);

  const filterNewsData = () => {
    return newsData.filter((val) => {
      return val.id === id;
    });
  };
  return (
    <div className="viewNews">
      <div className="viewNews_header_container">
        <img className="viewNews_img" src={header} alt="" />
      </div>
      <div className="viewNews_container">
        {filterNewsData()?.map((news, index) => {
          return (
            <article class="article">
              <div class="article-image">
                <img src={news.picture} alt="Article Image" />
              </div>
              <div class="article-content">
                <h2 class="article-title">{news.title}</h2>
                <p class="article-text">{news.text}</p>
              </div>
            </article>
          );
        })}
      </div>
      <br />
      <Footer />
    </div>
  );
}

export default ViewNews;
