import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Sidebar from "./Sidebar.js";
import "./Navbar.scss";
import facebook_logo from "../images/facebook_logo.png";
import logo_nab from "../images/logo_nab.png";
import transparency_logo from "../images/transparency-seal.jpg";

function Navbar() {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const api_key = process.env.API_KEY;
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  const formattedDateTime = currentDateTime.toLocaleString(undefined, options);
  return (
    <div className="navbar">
      <Sidebar />
      {/* <Sidebar2 /> */}
      <div className="wrapper">
        <div className="wrapper_span_container">
          <motion.span
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{
              opacity: 1,
              scale: 1,
            }}
            transition={{ duration: 0.5 }}
          >
            GOVPH
          </motion.span>
        </div>

        <div className="socials">
          <div className="socials_a1_container">
            <motion.a
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{
                opacity: 1,
                scale: 1,
              }}
              transition={{ duration: 0.5 }}
              href="#"
            >
              <img
                className="transparency_logo"
                src={transparency_logo}
                alt=""
              />
            </motion.a>
          </div>

          <motion.a
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{
              opacity: 1,
              scale: 1,
            }}
            transition={{ duration: 0.5 }}
            href="https://www.facebook.com/nabunturanlgu"
            target="_blank"
            rel="noreferrer"
          >
            <a href="#">{api_key}</a>
            <div className="facebookLogo_container">
              <img className="facebook_logo" src={facebook_logo} alt="" />{" "}
              &nbsp;
              <span>Facebook</span>
            </div>
          </motion.a>
          <div className="socials_dateTime_container">
            <a href="#">
              <span>{formattedDateTime}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
