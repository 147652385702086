import React from "react";
import "./InvitationToBid.scss";
import bids_1 from "../images/bids_1.jpg";
import bids_2 from "../images/bids_2.jpg";
import bids_3 from "../images/bids_3.jpg";
import header from "../images/header-1.png";
import Footer from "./Footer";

// bids pic
import lota1 from "../images/LotA1.jpg";
import lota2 from "../images/LotA2.jpg";
import lota3 from "../images/LotA3.jpg";

import lota4 from "../images/LotA4.jpg";
import lota5 from "../images/LotA5.jpg";
import lota6 from "../images/LotA6.jpg";

import lota7 from "../images/LotA7.jpg";
import lota8 from "../images/LotA8.jpg";
import lota9 from "../images/LotA9.jpg";

import lota10 from "../images/LotA10.jpg";
import lota11 from "../images/LotA11.jpg";
import lota12 from "../images/LotA12.jpg";

import lota13 from "../images/LotA13.jpg";
import lota14 from "../images/LotA14.jpg";

import lotb1 from "../images/LotBMotorcycle1.jpg";
import lotb2 from "../images/LotBMotorcycle2.jpg";
import lotb3 from "../images/LotBMotorcycle3.jpg";

import lotc1 from "../images/LotC1.jpg";

import lotd1 from "../images/LotD1.jpg";

import lote1 from "../images/LotE1.jpg";

function InvitationToBid() {
  return (
    <div className="invitationToBid">
      <div className="img_container">
        <img src={header} alt="" />
      </div>
      <div className="wrapper">
        <img className="invitationToBid_img" src={bids_1} alt="" />
        <img className="invitationToBid_img" src={bids_2} alt="" />
        <img className="invitationToBid_img" src={bids_3} alt="" />
      </div>
      <br />
      <h1>LOT A - CONSTRUCTION AND HEAVY EQUIPMENT</h1>
      <div className="photo-gallery">
        {/* 1st row */}
        <div class="gallery-item">
          <img className="gallery_img" src={lota1} alt="Image 1" />
          <div class="caption">Dumptruck - Nissan(01) - Junk 10-wheeler</div>
        </div>
        <div class="gallery-item">
          <img className="gallery_img" src={lota2} alt="Image 1" />
          <div class="caption">Dumptruck - Nissan(02) - Junk 10-wheeler</div>
        </div>
        <div class="gallery-item">
          <img className="gallery_img" src={lota3} alt="Image 1" />
          <div class="caption">Engine w/Transmission, Surplus-Junk</div>
        </div>
        {/* 2nd row */}
        <div class="gallery-item">
          <img className="gallery_img" src={lota4} alt="Image 1" />
          <div class="caption">Loader, Samsung, Surplus</div>
        </div>
        <div class="gallery-item">
          <img className="gallery_img" src={lota5} alt="Image 1" />
          <div class="caption">Motor Grader, Komatsu GD31, Surplus-Junk</div>
        </div>
        <div class="gallery-item">
          <img className="gallery_img" src={lota6} alt="Image 1" />
          <div class="caption">
            Vibratory Road Roller, Sakai TG25, Surplus-Junk
          </div>
        </div>
        {/* 3rd row */}
        <div class="gallery-item">
          <img className="gallery_img" src={lota7} alt="Image 1" />
          <div class="caption">Elf Mounted Crane, Isuzu 4DE1, Surplus</div>
        </div>
        <div class="gallery-item">
          <img className="gallery_img" src={lota8} alt="Image 1" />
          <div class="caption">Dumptruck-Isuzu 10wheeler,Surplus-Junk</div>
        </div>
        <div class="gallery-item">
          <img className="gallery_img" src={lota9} alt="Image 1" />
          <div class="caption">Excavator-Komatsu,Surplus,Junk</div>
        </div>
        {/* 4th row */}
        <div class="gallery-item">
          <img className="gallery_img" src={lota10} alt="Image 1" />
          <div class="caption">Dumptruck, Isuzu 10 wheeler, Surplus - Junk</div>
        </div>
        <div class="gallery-item">
          <img className="gallery_img" src={lota11} alt="Image 1" />
          <div class="caption">Buldozer, Caterpillar-D-6-C, Surplus Junk</div>
        </div>
        <div class="gallery-item">
          <img className="gallery_img" src={lota12} alt="Image 1" />
          <div class="caption">
            Motor Grader, Mitsubishi LG2H, Surplus - Junk
          </div>
        </div>
        {/* 5th row */}
        <div class="gallery-item">
          <img className="gallery_img" src={lota13} alt="Image 1" />
          <div class="caption">
            Dumptruck, Isuzu, 10 wheeler, Surplus - Junk
          </div>
        </div>
        <div class="gallery-item">
          <img className="gallery_img" src={lota14} alt="Image 1" />
          <div class="caption">
            Dumptruck, Nissan - Junk 6-wheeler (Donated by Province of Davao Del
            Norte)
          </div>
        </div>
      </div>
      <h1>LOT B - MOTOR VEHICLES</h1>
      <div className="photo-gallery">
        {/* 1st row */}
        <div class="gallery-item">
          <img className="gallery_img" src={lotb1} alt="Image 1" />
          <div class="caption">Motorcycle - Junk Honda Dream</div>
        </div>
        <div class="gallery-item">
          <img className="gallery_img" src={lotb2} alt="Image 1" />
          <div class="caption">Motorcycle - Junk Yamaha, DT 125cc</div>
        </div>
        <div class="gallery-item">
          <img className="gallery_img" src={lotb3} alt="Image 1" />
          <div class="caption">Motorcycle - Junk Honda XL 125cc</div>
        </div>
      </div>
      <h1>LOT C - AGRICULTURAL AND MACHINERY EQUIPMENT</h1>
      <div className="photo-gallery">
        {/* 1st row */}
        <div class="gallery-item">
          <img className="gallery_img" src={lotc1} alt="Image 1" />
          <div class="caption">Farm Tractor, Kubota, 92 Hp</div>
        </div>
      </div>
      <h1>
        LOT D - ASSORTED UNSERVICEABLE MACHINERY, OFFICE, IT , COMMUNICATION,
        DISASTER RESCUE AND RESPONSE EQUIPMENT, MEDICAL,TECHNICAL AND
        SCIENTIFIC, FURNITURES AND FIXTURES, OTHER PROPERTY PLANT EQUIPMENT
      </h1>
      <div className="photo-gallery">
        {/* 1st row */}
        <div class="gallery-item">
          <img className="gallery_img" src={lotd1} alt="Image 1" />
          <div class="caption">
            Assorted unserviceable items such as motorpool facilities,
            Photocopying Machines, Desktop and Laptop Computers and IT
            peripherals, Handleradios and base, Microscope, Surverying
            intruments, Room Airconditioners, etc. found in the Bodega and
            should be bidded per kilogram as JUNK as set by the Appraisal and
            Disposal Committee. Complete lists of these items is attached
          </div>
        </div>
      </div>
      <h1>LOT E - ASSORTED WASTE MATERIALS OF MOTOR VEHICLES, ETC</h1>
      <div className="photo-gallery">
        {/* 1st row */}
        <div class="gallery-item">
          <img className="gallery_img" src={lote1} alt="Image 1" />
          <div class="caption">
            Miscellaneous Waste Materials of Motor Vehicles, Construction
            Materials (Metals) etc. and should be bidded in the manner as
            specified in Lot D1
          </div>
        </div>
      </div>{" "}
      <br />
      <Footer />
    </div>
  );
}

export default InvitationToBid;
