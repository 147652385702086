import React, { useEffect, useState } from "react";
import bids_and_awards_header from "../images/bids_and_awards_header.png";
import Footer from "./Footer";
import "./BidsNoticeOfAward.scss";
import { FaFilePdf } from "react-icons/fa";
//firebase
import { collection, doc, getDocs } from "firebase/firestore";
import db from "../firebase";

function BidsPublicBidding() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "public_bidding"));
      const items = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(items);
    } catch (error) {
      console.error("Error fetching documents: ", error);
    }
  };

  console.log("Data: " + data);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 5;
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
    setCurrentPage(1); // Reset to the first page when searching
  };

  // Filter data based on search query
  const filteredData = data.filter(
    (item) =>
      item.bid_number.toLowerCase().includes(searchQuery) ||
      item.project_title.toLowerCase().includes(searchQuery)
  );

  // Calculate the start and end index for pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);
  return (
    <div className="BidsNoticeOfAward">
      <img src={bids_and_awards_header} alt="" />
      <br />
      <h1>Public Bidding</h1>
      <div className="BidsNoticeOfAward_container">
        <div class="table-container">
          <input
            type="text"
            id="searchInput"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
          />

          <table id="dataTable">
            <thead>
              <tr>
                <th>Bid Number</th>
                <th>Project Name</th>

                <th>Click to View</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item, index) => (
                <tr key={index}>
                  <td>{item.bid_number}</td>
                  <td>{item.project_title}</td>

                  <td>
                    <a href={item.link} target="__blank" className="link">
                      <FaFilePdf />
                    </a>
                  </td>
                  {/* Render more columns if needed */}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Prev
            </button>
            <span>{currentPage}</span>
            <button
              onClick={handleNextPage}
              disabled={endIndex >= filteredData.length}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </div>
  );
}

export default BidsPublicBidding;
