import React from "react";
import "./BidsAndAwards.scss";
import bids_and_awards_header from "../images/bids_and_awards_header.png";
import Footer from "./Footer";

// icons
import { TbReportSearch } from "react-icons/tb";
import { BiDetail } from "react-icons/bi";
import { PiListBulletsFill } from "react-icons/pi";
import { FaFileContract } from "react-icons/fa";
import { AiOutlineDeliveredProcedure } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaAward } from "react-icons/fa6";
import { RiGovernmentFill } from "react-icons/ri";
import { FaShoppingCart } from "react-icons/fa";
import { MdSmsFailed } from "react-icons/md";

function BidsAndAwards() {
  return (
    <div className="bidsAndAwards">
      <img src={bids_and_awards_header} alt="" />
      <br />
      <div className="bidsAndAwards_container">
        <div className="test">
          <Link to="/bids-reports">
            <div className="bidsAndAwardContainer">
              {" "}
              <TbReportSearch className="icon" />
              <span>Reports</span>
            </div>
          </Link>
          <Link to="/public-bidding">
            <div className="bidsAndAwardContainer">
              {" "}
              <BiDetail className="icon" />
              <span>Public Bidding</span>
            </div>
          </Link>
          <Link to="/bid-bulletin">
            <div className="bidsAndAwardContainer">
              {" "}
              <PiListBulletsFill className="icon" />
              <span>Bid Bulletin</span>
            </div>
          </Link>
          <Link to="/notice-of-award">
            <div className="bidsAndAwardContainer">
              {" "}
              <FaAward className="icon" />
              <span>Notice of Award</span>
            </div>
          </Link>
        </div>
        {/* 2nd row */}
        <div className="test">
          <Link to="/bids-award-notice-to-proceed">
            <div className="bidsAndAwardContainer">
              {" "}
              <AiOutlineDeliveredProcedure className="icon" />
              <span> Notices to Proceed</span>
            </div>
          </Link>
          <Link to="/bid-svp">
            <div className="bidsAndAwardContainer">
              {" "}
              <RiGovernmentFill className="icon" />
              <span> SVP</span>
            </div>
          </Link>
          <Link to="/bid-shopping">
            <div className="bidsAndAwardContainer">
              {" "}
              <FaShoppingCart className="icon" />
              <span> Shopping</span>
            </div>
          </Link>
          <Link to="/bid-two-failed-biddings">
            <div className="bidsAndAwardContainer">
              {" "}
              <MdSmsFailed className="icon" />
              <span> Two-failed Biddings</span>
            </div>
          </Link>
        </div>
      </div>
      <br />
      <Footer />
    </div>
  );
}

export default BidsAndAwards;
