import { initializeApp } from "firebase/app";

// Import the functions you need from the SDKs you need

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getFirestore, collection, getDocs, doc } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA4vvi0PJEkugQWm2MNmhuJTNsYXDx9ZAc",
  authDomain: "lgu-website-bac-server.firebaseapp.com",
  projectId: "lgu-website-bac-server",
  storageBucket: "lgu-website-bac-server.appspot.com",
  messagingSenderId: "692160663742",
  appId: "1:692160663742:web:e9ea1396f7345c3f958eef",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;
