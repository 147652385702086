import React from "react";
import "./TouristDestinations.scss";
import TouristDestinationsCard from "./TouristDestinationsCard.js";
import header from "../images/header-1.png";
import damada from "../images/camp_damada.png";
import grape_valley from "../images/grape_valley.png";
import cave from "../images/cave.png";
import toyozu from "../images/toyuzo.png";
import junora from "../images/junora.jpg";
import aqualime from "../images/aqualime.jpg";
import mipangi from "../images/mipangi_hilltop.jpg";
import jv from "../images/jv_place.jpg";
import balutan from "../images/balutan.jpg";
import tugonan from "../images/tugonan_falls.jpg";
import nafamco from "../images/diary_farm.jpg";
import green_garden from "../images/green_garden.jpg";
import davana from "../images/davana_paradise.jpg";
import saosao from "../images/saosao_falls.jpg";
import chicken_farm from "../images/chicken_farm.jpg";
import bingka from "../images/bingka_suman.jpg";
import Footer from "./Footer.js";

function TouristDestinations() {
  return (
    <div className="touristDestinations">
      <div className="img_container">
        <img src={header} alt="" />
      </div>
      <div className="touristDestinations__container">
        <h1 className="touristDestinations__container_h1">
          Tourist Destinations
        </h1>
        <div className="touristDestinations_cards_container">
          <TouristDestinationsCard
            img={damada}
            title="Camp Damada (0966-366-7617)"
            description="A humble kapehan overlooking, Nabunturan; a Favorite hideaway site for campers and nature's enthusiast who wishes to leave the bustle of downtown for good food and great view."
          />
          <TouristDestinationsCard
            img={grape_valley}
            title="Grape Valley (0930-636-2808/0917-304-0026"
            description="Located at Sta. Maria, Nabunturan, just 10 minutes from the provincial capitol,lies the 1.2 hactares with over 1,000 hills of grape,plants and 24 table and wine grape varieties.Enjoy the Grape-picking experience!"
          />
          <TouristDestinationsCard
            img={cave}
            title="Malanog-lanog Cave (0946-130-4907/0970-392-0542"
            description="Malanog-lanog Cave Located in Tindalo, Mainit, Nabunturan, Davao De Oro, Malanog-lanog Cave is 15.4 kilometers from the Poblacion. It is a very accessible show cave and perfect for a spelunking activity for inexperience cavers."
          />
        </div>{" "}
        <br />
        {/* 2nd row */}
        <div className="touristDestinations_cards_container">
          <TouristDestinationsCard
            img={toyozu}
            title="Toyozu Mountain Resort"
            description="Set in the heart of Barangay Bukal, a resort well-loved for its steam baths and hot swimming pools harnessed from the hot sulfuric springs of the Mainit Hotspring Protected Landscape."
          />
          <TouristDestinationsCard
            img={junora}
            title="Junora Family Inland Resort"
            description="Situated in Barangay Manat, it features a 3 Large pools with different accomodations to choose from it one wishes for an overnight stay. Also famous for a go to venue for weddings and birthdays."
          />
          <TouristDestinationsCard
            img={aqualime}
            title="Aqualime Swimming Pools"
            description="Located at 92 Barangay Magsaysay, it offers two swimming pools full of cool and refreshing water, one for adult use and the other for children inside its 2 hectare resort covered with thick forest."
          />
        </div>
        {/* 3rd row */} <br />
        <div className="touristDestinations_cards_container">
          <TouristDestinationsCard
            img={mipangi}
            title="Mipangi Hilltop"
            description="Mipangi Hills, Nabunturan | Around 700-800 masl, you will bask the 360-degree view of the mountains of Davao del Norte and Davao de Oro.Perfect early morning hike (4am) to welcome the beauty of sunrise."
          />
          <TouristDestinationsCard
            img={jv}
            title="JV's Place"
            description="Another part of the San Isidro highland food hub, a favorite go to place for chill drinkers while enjoying the live band and overlooking the lights of the towns as the crips night air embraces the area"
          />
          <TouristDestinationsCard
            img={balutan}
            title="Mountain View Balutan"
            description="A perfect place to chill,relax and welcome the sunrise breaking from the sea of clouds while sipping a locally made delicious sikwate of Brgy. San Isidro. In the afternoon one can enjoy a fresh balut and bask in the beauty of the highland."
          />
        </div>
        {/* 4th row */} <br />
        <div className="touristDestinations_cards_container">
          <TouristDestinationsCard
            img={tugonan}
            title="Tugonan Falls"
            description="Located in the up-skirts of Sitio Apogon, Barangay Katipunan, a waterfall with an Approximate elevation of 20 meterss height. It is believed by the native residents to be the dwelling place of enchanted beings."
          />
          <TouristDestinationsCard
            img={nafamco}
            title="NAFAMCO Dairy Farm"
            description="The Nabunturan Farmers Multipurpose Cooperative (NAFAMCO) is one of the most established farmer's cooperative in the municipality which started in the year 1995 mainly providing assitance to it's members. Now, it has successfully expanded into a dairy farm-milk processing and production. Its 9.5 hectares of land is grazed by imported breed of Jersey cattles in which 23 out of 57 are considered as milking cows."
          />
          <TouristDestinationsCard
            img={green_garden}
            title="Green Garden Fruit and Flower Farm in New Sibonga"
            description="A perfect place to celebrate intimate occasions and spend relaxing and peaceful moment while endowing one's eyes in the beauty of the area. A new farm in Brgy. New Sibonga that both offers a day tour and night tour that will help the visitors to loosen up, enjoy its cool waters, and rest in the glamping experience the place has to offer."
          />
        </div>
        {/* 5th row */} <br />
        <div className="touristDestinations_cards_container">
          <TouristDestinationsCard
            img={davana}
            title="Davana Paradise"
            description="An inclusive resort hiding in the outskirts of Brgy. Mipangi is waiting to be relished to those who wishes to clear their mind and be released from all the toxicity of their daily life without the stresses of sharing the place with strangers."
          />
          <TouristDestinationsCard
            img={saosao}
            title="SaoSao Falls"
            description="A discovered falls in the middle of lockdown. The year 2020 brought the boredom out of the Nabunturanons and taught the citizens to reconnect with nature one of which is the infamous Sao-Sao falls tha has been contested by two municipalities. One municipality historically claimed its origins and the other based by location, neverthless, another water formation of the locality that is a must visit for nature lovers."
          />
          <TouristDestinationsCard
            img={chicken_farm}
            title="Nabunturan Chicken Farm"
            description="Nabunturan Chicken Farm located in Barangay Anislagan, this 7-hectare from breeds free-range chicken and houses training on poultry raising."
          />
        </div>{" "}
        <br />
        <div className="touristDestinations_cards_container">
          <TouristDestinationsCard img={bingka} title="" />
        </div>
      </div>
      <br />
      <Footer />
    </div>
  );
}

export default TouristDestinations;
