import React from "react";
import "./TouristDestinationsCard.scss";

function TouristDestinationsCard({ img, title, description }) {
  return (
    <div className="TouristDestinationsCard_card">
      <img
        className="TouristDestinationsCard_card__image"
        src={img}
        alt="Card"
      />
      <div className="TouristDestinationsCard_card__content">
        <h2 className="TouristDestinationsCard_card__title">{title}</h2>
        <p className="TouristDestinationsCard_card-description">
          {description}
        </p>
      </div>
    </div>
  );
}

export default TouristDestinationsCard;
