import React, { useRef, useState } from "react";
import "./AwardsSection.scss";
import Modal from "./Modal";
// images
import award1 from "../images/2nd_dynamic.png";
import award2 from "../images/2nd_innovation.png";
import award3 from "../images/5th_most_competitive.png";

// icons
import { FaEarthAmericas } from "react-icons/fa6";
import { IoShareSocial } from "react-icons/io5";
import { FaEnvira } from "react-icons/fa";
import { FaRoad } from "react-icons/fa";
import { FaBuildingColumns } from "react-icons/fa6";
import { MdAddBusiness } from "react-icons/md";
import { IoBusiness } from "react-icons/io5";
import { LiaBusinessTimeSolid } from "react-icons/lia";

import { useScroll, motion, useTransform } from "framer-motion";
import { Link } from "react-router-dom";

function AwardsSection() {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div className="awardsSection">
      <Modal isOpen={isOpen} onClose={closeModal}>
        <div className="modal_container">
          <a href="https://filipizen.com/partners/ddo_nabunturan/bpls/billing">
            <div className="plansAndProgramContainer">
              <IoBusiness className="icon" />
              <span>Business Online Billing</span>
            </div>
          </a>
          <a href="https://filipizen.com/partners/ddo_nabunturan/bpls/newbusiness">
            <div className="plansAndProgramContainer">
              <MdAddBusiness className="icon" />
              <span>New Business Registration</span>
            </div>
          </a>
          <a href="https://www.filipizen.com/partner/ddo_nabunturan/bpls/renewbusiness">
            <div className="plansAndProgramContainer">
              <LiaBusinessTimeSolid className="icon" />
              <span>Renew Business Application</span>
            </div>
          </a>
        </div>

        <button onClick={closeModal}>Close</button>
      </Modal>
      {/* Up Container */}
      <div className="upContainer">
        <div className="card">
          <img className="card__image" src={award1} alt="Card" />
          <div className="card__content">
            <h2 className="card__title">
              2nd Most Competitive in Economic Dynamism
            </h2>
          </div>
        </div>
        <div className="card">
          <img className="card__image" src={award2} alt="Card" />
          <div className="card__content">
            <h2 className="card__title">2nd Most Competitive in Innovation</h2>
          </div>
        </div>
        <div className="card">
          <img className="card__image" src={award3} alt="Card" />
          <div className="card__content">
            <h2 className="card__title">
              5th Overall Most Competitive <br />
              <br />
            </h2>
          </div>
        </div>
      </div>
      <br />
      <br />
      {/* Down Container */}
      <motion.div className="downContainer">
        <div></div>
        {/* <motion.div className="leftContainer">
          <h2>Plans and Programs</h2>
          <div className="container">
            <Link to="/economic">
              <div className="plansAndProgramContainer">
                <FaEarthAmericas className="icon" />
                <span>Economic</span>
              </div>
            </Link>

            <div className="plansAndProgramContainer">
              <IoShareSocial className="icon" />
              <span>Social</span>
            </div>
            <div className="plansAndProgramContainer">
              {" "}
              <FaEnvira className="icon" />
              <span>Environment</span>
            </div>
            <div className="plansAndProgramContainer">
              {" "}
              <FaRoad className="icon" />
              <span>Infrastructure</span>
            </div>
            <div className="plansAndProgramContainer">
              {" "}
              <FaBuildingColumns className="icon" />
              <span>Institution</span>
            </div>
          </div>
        </motion.div> */}
        <motion.div className="rightContainer">
          <div className="upper">
            <h2>Services</h2>
          </div>
          <div className="middle">
            <p>
              LGU Nabunturan offers various services that will cater to the
              needs of the people. The LGU Nabunturan encourages the
              nabunturanons to reach out to the different departments to inquire
              of the services that are available in offices.
            </p>
            <br />
          </div>
          <div className="bottom">
            <div onClick={openModal} className="plansAndProgramContainer">
              {" "}
              <IoBusiness className="icon" />
              <span>Business</span>
            </div>
            <a href="https://filipizen.com/partners/ddo_nabunturan/rptis/billing">
              <div className="plansAndProgramContainer">
                {" "}
                <MdAddBusiness className="icon" />
                <span>Real Property</span>
              </div>
            </a>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default AwardsSection;
