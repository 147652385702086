import React from "react";
import "./Festival.scss";
import header from "../images/header-1.png";
import Footer from "./Footer";
import simballay from "../images/simbalay.jpg";

function Festival() {
  return (
    <div className="festival">
      <div className="img_container">
        <img src={header} alt="" />
      </div>
      <div className="container">
        <article class="article">
          <div class="article-image">
            <img src={simballay} alt="Article Image" />
          </div>
          <div class="article-content">
            <h2 class="article-title">
              History of Simballay Festival <br />
            </h2>
            <p class="article-text">
              Simballay is an annual festivity of the Municipality of Nabunturan
              coined from a Mansaka word meaning, “Togetherness, sharing and
              thanksgiving for a bountiful harvest.” It came into existence in
              1994 when Nabunturan was named as a Provincial Agri-Industrial
              Center in Region XI alongside with the municipalities in Tagum and
              Panabo which had now become cities except Nabunturan. These three
              municipalities were also known as PANATAG – Panabo, Tagum and
              Tagum corridor. Simballay Festival was conceived as the likes of
              the Sinulog in Cebu City and the Kadayawan Festival in Davao City
              showcasing potential resources to attract investors in Nabunturan.
              It was not until 1994 when it was initiated by former Mayor, now
              Father Joel O. Bugas.
            </p>
          </div>
        </article>
        <article>
          <div className="article-content">
            <p class="article-text">
              For years, Simballay became a tradition to the Nabunturanons every
              second week of December, the month of family togetherness and
              sharing of gifts and blessings. It was also a symbol of honoring
              indigenous peoples. The Nabunturanons took respite from farms and
              works to gather in this most awaited event of the year. Women are
              busy preparing local costumes, men decorating buntings on the
              streets and building authentic cottages with abundant display of
              teeming local products, children peeping in the crowded streets
              witnessing the merry making celebrations. Week-long activities
              were lined up for the people to the feast. Tourist come and go. It
              was a restless activity of people in the jubilant festive mood.
            </p>
          </div>
        </article>
        <article>
          <div className="article-content">
            <p class="article-text">
              Among the festivities that showcased the true colors of the
              Mansaka was the street dancing. Barangay people participate with
              their various interpretations of the natives’ tradition and
              culture. It was such a vivid display of ethnic costumes and dance
              steps with the grace plus the authentic sound of “agong” and other
              ethnic instruments amplified all throughout the busy streets along
              the merry crowd. In its totality, Simballay was a symbol of unity
              and progress. It made Nabunturan well known for its potential
              resources in tourism and agro-industrial aspects. As it evolved,
              many of the industries blossomed until Nabunturan became the
              capital town of Compostela Valley in 1998. The spirit of Simballay
              Festival still loomed. The festivity had now become a significant
              event in the municipality. Nabunturan, now as a major center of
              the province of Compostela Valley and potential tourist
              destination needs Simballay Festival in its prime to sustain the
              essence of sharing, togetherness and thanksgiving for a bountiful
              harvest which our people had long lived for.
            </p>
          </div>
        </article>
        <article>
          <div className="article-content">
            <p class="article-text">
              Resolution No. 214 was approved on December 8, 1988 amending
              Resolution No. 175-A. From a one week celebration of Simballay in
              the second week of December, it was trimmed down in two days and
              was moved to the month of July with the Celebration of Araw ng
              Nabunturan on July 21-22. In 2003, Ordinance No 2 declared
              December 16-22 of every year as Simballay Festival which restored
              the festivity to its original Month. However the festival was
              again transferred to October 15-19 of every year effective year
              2008 by the virtue of Amendatory Ordinance NO 2007-01. A year
              later, the Municipal Tourism Council (MTC) requested the
              Sangguniang Bayan of Nabunturan to amend the dates of the
              celebration from the third week of October to the third week of
              December of every year. This was supported by manifesto of the
              indigenous people and other concerned residents of LGU Nabunturan
              who conveyed their indifference, apathy or opposition to
              Amendatory Ordinance No. 2007-01. The DepEd of ComVal and the
              Association of Barangay Council (ABC) of Nabunturan were also for
              the celebration of Simballay in December.
            </p>
          </div>
        </article>
        <article>
          <div className="article-content">
            <p class="article-text">
              Besides, the Department of Tourism Calendar of Festival in the
              Philippines has set the Simballay Festival of Nabunturan on the
              third week of December. Hence, Municipal Ordinance No 2009-06
              fixed the date of the celebration of the Simballay Festival from
              the third week of October to the third week of December of every
              year effective December 2009.
            </p>
          </div>
        </article>
      </div>

      <Footer />
    </div>
  );
}

export default Festival;
