import React, { useEffect } from "react";
import "./History.scss";
import Header from "../images/header-1.png";
import lgu from "../images/municipyo2.jpg";
import Footer from "./Footer";
import VissionMissionGoalCard from "./VissionMissionGoalCard";
import { Link } from "react-router-dom";

function History() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="history">
      <div className="img_container">
        <img src={Header} alt="" />
      </div>
      <div className="container">
        <article class="article">
          <div class="article-image">
            <img src={lgu} alt="Article Image" />
          </div>
          <div class="article-content">
            <h2 class="article-title">
              Municipality of Nabunturan <br />
              <span> Municipal Hall</span>
            </h2>
            <p class="article-text">
              The town of Nabunturan was once a barangay of Municipality of
              Compostela. The origin of the name is from buntod, the Cebuano
              term for “mountain.” The word “Nabunturan” means “surrounded by
              mountains” in English. The early settlement was governed by a
              headman called Bagani, but was under the supervision of the
              Municipal District President of Compostela over matters concerning
              civil affairs. For the maintenance of peace and order,the area was
              under the immediate supervision of the Philippine Constabulary
              Detachment of Camp Kalaw, Moncayo. From barangay Jaguimitan in the
              north to barangay Mawab (now a municipality in the south), the
              whole Compostela town was divided into two (2) municipal
              districts: the district of Moncayo, and the district of
              Compostela, with the latter’s seat of local government in barangay
              Nabunturan, now the municipality of Nabunturan.
            </p>
          </div>
        </article>

        <article>
          <div className="article-content">
            <p class="article-text">
              Before the construction of the national road traversing
              Nabunturan, the place was little know; but despite the threat of
              malaria, which was prevalent at the time, pioneers ventured into
              the area. Mansaka natives settled their lives here in the
              municipality of Mawab to Barangay Bangkerohan, Montevista. The
              original site of Barangay Nabunturan was situated along the
              Libasan-Saosao Provincial Road, about 4 km from the present
              Poblacion site. The public school system was under the supervision
              of the Supervising Teacher stationed in the Kalaw Settlement for
              schools in the district of Monkayo.
            </p>
          </div>
        </article>
        <article>
          <div className="article-content">
            <p class="article-text">
              When the road was constructed in 1938, many Visayan settlers and
              pioneers came to Nabunturan and made it their home. By that time,
              the establishment of the Bureau of Public Works (BPW) camp settled
              in Nabunturan. Many business trades and establishments poured in
              and Nabunturan was established as a rural community.
            </p>
          </div>
        </article>
        <article>
          <div className="article-content">
            <p class="article-text">
              In 1945, American forces landed in Davao City, and the Philippine
              Civil Assistance Unit (PCAU) established the civil government of
              Compostela at Nabunturan because of its accessibility to Davao
              City. By that time, many highways and roads were constructed, and
              Nabunturan became a center of commerce. Because of this, the need
              to convert Nabunturan to a municipality was felt. On July 23,
              1957, 30 days after the approval and passage of Republic Act no.
              2038, a new political unit—separate and distinct from the mother
              municipality of Compostela—was created. It retained its original
              name, Nabunturan. The first mayor of the town was Lauro C.
              Arabejo, the incumbent mayor of the municipality of Compostela.
            </p>
          </div>
        </article>
        <article>
          <div className="article-content">
            <p class="article-text">
              On January 30, 1998, President Fidel V. Ramos signed into law the
              creation of the 79th Province of the country, Compostela Valley,
              pursuant to Rep. Act 8470, which created Nabunturan as its Capital
              town on March 8, 1998. Prospero Amatong, then incumbent governor
              of Davao del Norte Province from which the new province of
              Compostela Valley was carved out, opted to serve as the first
              governor of the newly created province. He only served for one day
              because he resigned the following day and filed his candidacy for
              Congress. He was succeeded by Luz Sarmiento as an appointed
              governor and served for two months. The first elected governor was
              Hon. Jose R. Caballero who served from July 1, 1998, to June 30,
              2007. The present Mayor of Nabunturan is{" "}
              <Link to="/" className="history_link">
                Hon. Myrocel C. Balili
              </Link>
              . Nabunturan is also home to the biggest gold ring in the
              Philippines, “The Solidarity Ring.”
            </p>
          </div>
        </article>
      </div>
      <div className="vmg_container">
        <VissionMissionGoalCard
          title="Vission"
          text="A dynamic and active partner in the revenue generation in
        sustaining socio-economic growth and development operating
        within the Municipality of Nabunturan."
        />
        <VissionMissionGoalCard
          title="Mission"
          text="Provide a systematic and accessible records on all Real Property
            assessments and to deliver basic services to the constituents of
            Nabunturan."
        />
        <VissionMissionGoalCard
          title="Goal"
          text="Through continued effort between taxpayers and the Local
          Government for the realization in uplifting the living condition
          of Nabunturanons."
        />
      </div>
      <Footer />
    </div>
  );
}

export default History;
