import "./app.scss";
import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useStateValue } from "./StateProvider.js";
// COMPONENTS
import Cursor from "./components/Cursor.js";
import ScrollIndicator from "./components/ScrollIndicator.js";
import Navbar from "./components/Navbar.js";
import BackToTopButton from "./components/BackToTopButton.js";
import History from "./components/History.js";
import Home from "./components/Home.js";
import LocalOfficials from "./components/LocalOfficials.js";
import LGUOffices from "./components/LGUOffices.js";
import Festival from "./components/Festival.js";
import TouristDestinations from "./components/TouristDestinations.js";
import Directory from "./components/Directory.js";
import Accounting from "./components/Accounting.js";
import NewsFeed from "./components/NewsFeed.js";
import ViewNews from "./components/ViewNews.js";
// import Economic from "./components/Economic.js";
import AssesorOffice from "./components/AssesorOffice.js";
import BudgetOffice from "./components/BudgetOffice.js";
import TreasurerOffice from "./components/TreasurerOffice.js";
import HealthOffice from "./components/HealthOffice.js";
import AgricultureOffice from "./components/AgricultureOffice.js";
import RegistrarOffice from "./components/RegistrarOffice.js";
import DevelopmentOffice from "./components/DevelopmentOffice.js";
import HrOffice from "./components/HrOffice.js";
import MswdOffice from "./components/MswdOffice.js";
import BploOffice from "./components/BploOffice.js";
import BidsAndAwards from "./components/BidsAndAwards.js";
import BidsReports from "./components/BidsReports.js";
import BidsBulletin from "./components/BidsBulletin.js";
import BidsPublicBidding from "./components/BidsPublicBidding.js";
import BidsNoticeOfAward from "./components/BidsNoticeOfAward.js";
import BidsAwardedToProceed from "./components/BidsAwardedToProceed.js";
import BidsSvp from "./components/BidsSvp.js";
import BidsShopping from "./components/BidsShopping.js";
import BidsTwoFailedBiddings from "./components/BidsTwoFailedBiddings.js";
import BacLogin from "./components/BacLogin.js";
import BacInputData from "./components/BacInputData.js";
import BacLoginButton from "./components/BacLoginButton.js";
import InvitationToBid from "./components/InvitationToBid.js";

function App() {
  // const isAdmin = true;
  const [{ user }, dispatch] = useStateValue();
  // const { user } = useAuth();
  return (
    <div className="app">
      {/* <ScrollIndicator /> */}
      <Cursor />
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />

        {/* For Residents Routes */}
        <Route path="/history" element={<History />} />
        <Route path="/local-officials" element={<LocalOfficials />} />
        <Route path="/offices" element={<LGUOffices />} />
        <Route path="/festival" element={<Festival />} />
        <Route path="/tourist-destination" element={<TouristDestinations />} />
        <Route path="/directory" element={<Directory />} />
        <Route path="/accounting" element={<Accounting />} />
        <Route path="/news" element={<NewsFeed />} />
        <Route path="/view-news/:id" element={<ViewNews />} />
        {/* <Route path="/economic" element={<Economic />} /> */}
        <Route path="/assesor-office" element={<AssesorOffice />} />
        <Route path="/budget-office" element={<BudgetOffice />} />
        <Route path="/treasury-office" element={<TreasurerOffice />} />
        <Route path="/health-office" element={<HealthOffice />} />
        <Route path="/agriculture-office" element={<AgricultureOffice />} />
        <Route path="/registrar-office" element={<RegistrarOffice />} />
        <Route path="/development-office" element={<DevelopmentOffice />} />
        <Route path="/hr-office" element={<HrOffice />} />
        <Route path="/mswd-office" element={<MswdOffice />} />
        <Route path="/bplo-office" element={<BploOffice />} />
        <Route path="/bids-and-awards" element={<BidsAndAwards />} />
        <Route path="/bids-reports" element={<BidsReports />} />
        <Route path="/bid-bulletin" element={<BidsBulletin />} />
        <Route path="/public-bidding" element={<BidsPublicBidding />} />
        <Route path="/notice-of-award" element={<BidsNoticeOfAward />} />
        <Route path="/bid-svp" element={<BidsSvp />} />
        <Route path="/bid-shopping" element={<BidsShopping />} />
        <Route path="/invitation-to-bid" element={<InvitationToBid />} />
        <Route
          path="/bid-two-failed-biddings"
          element={<BidsTwoFailedBiddings />}
        />
        <Route
          path="/bids-award-notice-to-proceed"
          element={<BidsAwardedToProceed />}
        />
        <Route path="/bac-login" element={<BacLogin />} />

        {/* <Route path="/bac-login" element={<BacLogin />} /> */}
        {/* <Route path="/bac-input" element={<BacInputData />} /> */}
        {user ? (
          <Route path="/bac-input" element={<BacInputData />} />
        ) : (
          <Route
            path="/bac-input"
            element={<Navigate to="/bac-login" replace />}
          />
        )}
      </Routes>

      <div className="fixed-bottom back_to_top_button__container">
        <BackToTopButton />
      </div>
      <div>
        <BacLoginButton />
      </div>
    </div>
  );
}

export default App;
