import React, { useEffect, useState } from "react";
import "./NewsFeed.scss";
import Footer from "./Footer.js";
import NewsCard from "./NewsCard.js";
import header from "../images/header-1.png";
import news1 from "../images/news1.jpg";
import news2 from "../images/news2.jpg";
import news3 from "../images/news3.jpg";
import news_data from "../news.json";
import { useNavigate } from "react-router-dom";

function NewsFeed() {
  const navigate = useNavigate();
  return (
    <div className="NewsFeed">
      <div className="img_container">
        <img src={header} alt="" />
      </div>
      <div className="NewsFeed__container">
        <h1 className="NewsFeed__container_h1">More News</h1>
        <div className="NewsFeed_cards_container">
          {news_data.map((news, index) => {
            return (
              <div className="NewsFeed_card">
                <img
                  className="NewsFeedCard_card__image"
                  src={news.picture}
                  alt="Card"
                />
                <div className="NewsFeedCard_card__content">
                  <h2 className="NewsFeedCard_card__title">{news.title}</h2>
                  <p className="NewsFeedCard_card-description">{news.text}</p>
                </div>
                <button
                  onClick={() => navigate(`/view-news/${news.id}`)}
                  className="NewsFeed_button"
                >
                  Read More
                </button>
              </div>
            );
          })}
        </div>{" "}
        <br />
      </div>
      <br />
      <Footer />
    </div>
  );
}

export default NewsFeed;
