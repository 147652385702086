import React, { useState } from "react";
import "./BacInputData.scss";
import bids_and_awards_header from "../images/bids_and_awards_header.png";
import Footer from "./Footer";
//firebase
import { collection, doc, getDocs, addDoc } from "firebase/firestore";
import db from "../firebase";

function BacInputData() {
  const getCurrentDateTime = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const currentDate = new Date();
    const month = months[currentDate.getMonth()];
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const seconds = currentDate.getSeconds().toString().padStart(2, "0");
    const timezoneOffset = currentDate.getTimezoneOffset() / -60; // Convert to hours, and negate to get UTC+8
    const timezone = `UTC${timezoneOffset >= 0 ? "+" : "-"}${Math.abs(
      timezoneOffset
    )
      .toString()
      .padStart(2, "0")}`;
    const meridiem = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${month} ${day}, ${year} at ${formattedHours}:${minutes}:${seconds} ${meridiem} ${timezone}`;
  };

  // Example usage
  console.log(getCurrentDateTime());

  const [selectedOption, setSelectedOption] = useState("");
  // Notice of Award
  const [noticeOfAwardPrNumber, setNoticeOfAwardPrNumber] = useState();
  const [noticeOfAwardProjectTitle, setNoticeOfAwardProjectTitle] = useState();
  const [noticeOfAwardSupplier, setNoticeOfAwardSupplier] = useState();
  const [noticeOfAwardModeOfProcurement, setNoticeOfAwardModeOfProcurement] =
    useState();
  const [noticeOfAwardLink, setNoticeOfAwardLink] = useState();

  // Notice to Proceed
  const [noticeToProceedPrNumber, setNoticeToProceedPrNumber] = useState();
  const [noticeToProceedProjectTitle, setNoticeToProceedProjectTitle] =
    useState();
  const [noticeToProceedSupplier, setNoticeToProceedSupplier] = useState();
  const [
    noticeToProceedModeOfProcurement,
    setNoticeToProceedModeOfProcurement,
  ] = useState();
  const [noticeToProceedLink, setNoticeToProceedLink] = useState();

  //Bid Bulletin
  const [bidBulletinBidNumberPrNumber, setBidBulletinBidNumberPrNumber] =
    useState();
  const [bidBulletinProjectTitle, setBidBulletinProjectTitle] = useState();
  const [bidBulletinAddenddumNumber, setBidBulletinAddenddumNumber] =
    useState();
  const [bidBulletinLink, setBidBulletinLink] = useState();

  // Public Bidding
  const [publicBiddingBidNumber, setPublicBiddingBidNumber] = useState();
  const [publicBiddingProjectTitle, setPublicBiddingProjectTitle] = useState();
  const [publicBiddingLink, setPublicBiddingLink] = useState();

  // SVP
  const [svpPrNumber, setSvpPrNumber] = useState();
  const [svpProjectTitle, setSvpProjectTitle] = useState();
  const [svpLink, setSvpLink] = useState();

  // Reports
  const [reportsReportDescription, setReportsReportDescription] = useState();
  const [reportsTitle, setReportsTitle] = useState();
  const [reportsLink, setReportsLink] = useState();

  // Shoppping
  const [shoppingPrNumber, setShoppingPrNumber] = useState();
  const [shoppingProjectTitle, setShoppingProjectTitle] = useState();
  const [shoppingLink, setShoppingLink] = useState();

  // Two Failed biddings
  const [twoFailedBiddingsPrNumber, setTwoFailedBiddingsPrNumber] = useState();
  const [twoFailedBiddingsProjectTitle, setTwoFailedBiddingsProjectTitle] =
    useState();
  const [twoFailedBiddingsLink, setTwoFailedBiddingsLink] = useState();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if Selected Option is Notice of Award
    if (selectedOption === "notice_of_award") {
      if (
        noticeOfAwardPrNumber === "" ||
        noticeOfAwardProjectTitle === "" ||
        noticeOfAwardSupplier === "" ||
        noticeOfAwardModeOfProcurement === "" ||
        noticeOfAwardLink === ""
      ) {
        alert("Fill all the Fields");
      } else {
        try {
          addDoc(collection(db, "notice_of_awards"), {
            pr_number: noticeOfAwardPrNumber,
            project_title: noticeOfAwardProjectTitle,
            supplier: noticeOfAwardSupplier,
            mode_of_procurement: noticeOfAwardModeOfProcurement,
            link: noticeOfAwardLink,
            created: getCurrentDateTime(),
          });
          alert("Add Successfull");
        } catch (error) {
          console.error("Error adding document", error);
        }
      }
    } else if (selectedOption === "notice_to_proceed") {
      if (
        noticeToProceedPrNumber === "" ||
        noticeToProceedProjectTitle === "" ||
        noticeToProceedSupplier === "" ||
        noticeToProceedModeOfProcurement === "" ||
        noticeToProceedLink === ""
      ) {
        alert("Fill all the Fields");
      } else {
        try {
          addDoc(collection(db, "notice_to_proceed"), {
            pr_number: noticeToProceedPrNumber,
            project_title: noticeToProceedProjectTitle,
            supplier: noticeToProceedSupplier,
            mode_of_procurement: noticeToProceedModeOfProcurement,
            link: noticeToProceedLink,
            created: getCurrentDateTime(),
          });
          alert("Add Successfull");
        } catch (error) {
          console.error("Error adding document", error);
        }
      }
    } else if (selectedOption === "bid_bulletin") {
      if (
        bidBulletinBidNumberPrNumber === "" ||
        bidBulletinProjectTitle === "" ||
        bidBulletinAddenddumNumber === "" ||
        bidBulletinLink === ""
      ) {
        alert("Fill all the Fields");
      } else {
        try {
          addDoc(collection(db, "bid_bulletin"), {
            bid_number_or_pr_number: bidBulletinBidNumberPrNumber,
            project_title: bidBulletinProjectTitle,
            addenddum_number: bidBulletinAddenddumNumber,
            link: bidBulletinLink,
            created: getCurrentDateTime(),
          });
          alert("Add Successfull");
        } catch (error) {
          console.error("Error adding document", error);
        }
      }
    } else if (selectedOption === "public_bidding") {
      if (
        publicBiddingBidNumber === "" ||
        publicBiddingProjectTitle === "" ||
        publicBiddingLink === ""
      ) {
        alert("Fill all the Fields");
      } else {
        try {
          addDoc(collection(db, "public_bidding"), {
            bid_number: publicBiddingBidNumber,
            project_title: publicBiddingProjectTitle,
            link: publicBiddingLink,
            created: getCurrentDateTime(),
          });
          alert("Add Successfull");
        } catch (error) {
          console.error("Error adding document", error);
        }
      }
    } else if (selectedOption === "svp") {
      if (svpPrNumber === "" || svpProjectTitle === "" || svpLink === "") {
        alert("Fill all the Fields");
      } else {
        try {
          addDoc(collection(db, "svp"), {
            pr_number: svpPrNumber,
            project_title: svpProjectTitle,
            link: svpLink,
            created: getCurrentDateTime(),
          });
          alert("Add Successfull");
        } catch (error) {
          console.error("Error adding document", error);
        }
      }
    } else if (selectedOption === "reports") {
      if (
        reportsTitle === "" ||
        reportsReportDescription === "" ||
        reportsLink === ""
      ) {
        alert("Fill all the Fields");
      } else {
        try {
          addDoc(collection(db, "reports"), {
            title: reportsTitle,
            report_description: reportsReportDescription,
            link: reportsLink,
            created: getCurrentDateTime(),
          });
          alert("Add Successfull");
        } catch (error) {
          console.error("Error adding document", error);
        }
      }
    } else if (selectedOption === "shopping") {
      if (
        shoppingPrNumber === "" ||
        shoppingProjectTitle === "" ||
        shoppingLink === ""
      ) {
        alert("Fill all the Fields");
      } else {
        try {
          addDoc(collection(db, "shopping_bidding"), {
            pr_number: shoppingPrNumber,
            project_title: shoppingProjectTitle,
            link: shoppingLink,
            created: getCurrentDateTime(),
          });
          alert("Add Successfull");
        } catch (error) {
          console.error("Error adding document", error);
        }
      }
    } else if (selectedOption === "two_failed_biddings") {
      if (
        twoFailedBiddingsPrNumber === "" ||
        twoFailedBiddingsProjectTitle === "" ||
        twoFailedBiddingsLink === ""
      ) {
        alert("Fill all the Fields");
      } else {
        try {
          addDoc(collection(db, "two_failed_bidding"), {
            pr_number: twoFailedBiddingsPrNumber,
            project_title: twoFailedBiddingsProjectTitle,
            link: twoFailedBiddingsLink,
            created: getCurrentDateTime(),
          });
          alert("Add Successfull");
        } catch (error) {
          console.error("Error adding document", error);
        }
      }
    }
  };
  return (
    <div className="bacInputData">
      <img src={bids_and_awards_header} alt="" />
      <br />
      <div className="bacInput_container">
        <form>
          <div className="form-group">
            <label htmlFor="selectOption">Select an option:</label>
            <select
              id="selectOption"
              value={selectedOption}
              onChange={handleOptionChange}
            >
              <option value="">Select...</option>
              <option value="notice_of_award">Notice of Award</option>
              <option value="notice_to_proceed">Notice to Proceed</option>
              <option value="bid_bulletin">Bid Bulletin</option>
              <option value="public_bidding">Public Bidding</option>
              <option value="svp">Svp</option>
              <option value="reports">Reports</option>
              <option value="two_failed_biddings">Two Failed Biddings</option>
              <option value="shopping">Shopping</option>
            </select>
            {/* <p>Selected option: {selectedOption}</p> */}
          </div>
          {/* If the selected is Notice of Award */}
          {selectedOption === "notice_of_award" && (
            <>
              <div className="form-group">
                <label htmlFor="pr_number">Pr Number:</label>
                <input
                  type="text"
                  value={noticeOfAwardPrNumber}
                  onChange={(e) => setNoticeOfAwardPrNumber(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="project_title">Project Title:</label>
                <input
                  type="text"
                  value={noticeOfAwardProjectTitle}
                  onChange={(e) => setNoticeOfAwardProjectTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="supplier">Supplier:</label>
                <input
                  type="text"
                  value={noticeOfAwardSupplier}
                  onChange={(e) => setNoticeOfAwardSupplier(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="mode_of_procurement">
                  Mode of Procurement:
                </label>
                <input
                  type="text"
                  value={noticeOfAwardModeOfProcurement}
                  onChange={(e) =>
                    setNoticeOfAwardModeOfProcurement(e.target.value)
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="link">Link:</label>
                <input
                  type="text"
                  value={noticeOfAwardLink}
                  onChange={(e) => setNoticeOfAwardLink(e.target.value)}
                />
              </div>
            </>
          )}
          {/* If the selected is Notice to Proceed */}
          {selectedOption === "notice_to_proceed" && (
            <>
              <div className="form-group">
                <label htmlFor="pr_number">Pr Number:</label>
                <input
                  type="text"
                  value={noticeToProceedPrNumber}
                  onChange={(e) => setNoticeToProceedPrNumber(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="project_title">Project Title:</label>
                <input
                  type="text"
                  value={noticeToProceedProjectTitle}
                  onChange={(e) =>
                    setNoticeToProceedProjectTitle(e.target.value)
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="supplier">Supplier:</label>
                <input
                  type="text"
                  value={noticeToProceedSupplier}
                  onChange={(e) => setNoticeToProceedSupplier(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="mode_of_procurement">
                  Mode of Procurement:
                </label>
                <input
                  type="text"
                  value={noticeToProceedModeOfProcurement}
                  onChange={(e) =>
                    setNoticeToProceedModeOfProcurement(e.target.value)
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="link">Link:</label>
                <input
                  type="text"
                  value={noticeToProceedLink}
                  onChange={(e) => setNoticeToProceedLink(e.target.value)}
                />
              </div>
            </>
          )}
          {/* If the selected is Bid Bulletin */}
          {selectedOption === "bid_bulletin" && (
            <>
              <div className="form-group">
                <label htmlFor="pr_number">Bid Number/Pr Number:</label>
                <input
                  type="text"
                  value={bidBulletinBidNumberPrNumber}
                  onChange={(e) =>
                    setBidBulletinBidNumberPrNumber(e.target.value)
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="project_title">Project Title:</label>
                <input
                  type="text"
                  value={bidBulletinProjectTitle}
                  onChange={(e) => setBidBulletinProjectTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="addenddum_number">Addenddum Number:</label>
                <input
                  type="text"
                  value={bidBulletinAddenddumNumber}
                  onChange={(e) =>
                    setBidBulletinAddenddumNumber(e.target.value)
                  }
                />
              </div>

              <div className="form-group">
                <label htmlFor="link">Link:</label>
                <input
                  type="text"
                  value={bidBulletinLink}
                  onChange={(e) => setBidBulletinLink(e.target.value)}
                />
              </div>
            </>
          )}
          {/* If the selected is Public Bidding */}
          {selectedOption === "public_bidding" && (
            <>
              <div className="form-group">
                <label htmlFor="bid_number">Bid Number:</label>
                <input
                  type="text"
                  value={publicBiddingBidNumber}
                  onChange={(e) => setPublicBiddingBidNumber(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="project_title">Project Title:</label>
                <input
                  type="text"
                  value={publicBiddingProjectTitle}
                  onChange={(e) => setPublicBiddingProjectTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="link">Link:</label>
                <input
                  type="text"
                  value={publicBiddingLink}
                  onChange={(e) => setPublicBiddingLink(e.target.value)}
                />
              </div>
            </>
          )}
          {/* If the selected is SVP */}
          {selectedOption === "svp" && (
            <>
              <div className="form-group">
                <label htmlFor="pr_number">Pr Number:</label>
                <input
                  type="text"
                  value={svpPrNumber}
                  onChange={(e) => setSvpPrNumber(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="project_title">Project Title:</label>
                <input
                  type="text"
                  value={svpProjectTitle}
                  onChange={(e) => setSvpProjectTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="link">Link:</label>
                <input
                  type="text"
                  value={svpLink}
                  onChange={(e) => setSvpLink(e.target.value)}
                />
              </div>
            </>
          )}
          {/* If the selected is Reports */}
          {selectedOption === "reports" && (
            <>
              <div className="form-group">
                <label htmlFor="report_description">Title:</label>
                <input
                  type="text"
                  value={reportsTitle}
                  onChange={(e) => setReportsTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="title">Report Description:</label>
                <input
                  type="text"
                  value={reportsReportDescription}
                  onChange={(e) => setReportsReportDescription(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="link">Link:</label>
                <input
                  type="text"
                  value={reportsLink}
                  onChange={(e) => setReportsLink(e.target.value)}
                />
              </div>
            </>
          )}
          {/* If the selected is Shopping */}
          {selectedOption === "shopping" && (
            <>
              <div className="form-group">
                <label htmlFor="pr_number">Pr Number:</label>
                <input
                  type="text"
                  value={shoppingPrNumber}
                  onChange={(e) => setShoppingPrNumber(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="project_title">Project Title:</label>
                <input
                  type="text"
                  value={shoppingProjectTitle}
                  onChange={(e) => setShoppingProjectTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="link">Link:</label>
                <input
                  type="text"
                  value={shoppingLink}
                  onChange={(e) => setShoppingLink(e.target.value)}
                />
              </div>
            </>
          )}
          {/* If the selected is two failed biddings */}
          {selectedOption === "two_failed_biddings" && (
            <>
              <div className="form-group">
                <label htmlFor="pr_number">Pr Number:</label>
                <input
                  type="text"
                  value={twoFailedBiddingsPrNumber}
                  onChange={(e) => setTwoFailedBiddingsPrNumber(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="project_title">Project Title:</label>
                <input
                  type="text"
                  value={twoFailedBiddingsProjectTitle}
                  onChange={(e) =>
                    setTwoFailedBiddingsProjectTitle(e.target.value)
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="link">Link:</label>
                <input
                  type="text"
                  value={twoFailedBiddingsLink}
                  onChange={(e) => setTwoFailedBiddingsLink(e.target.value)}
                />
              </div>
            </>
          )}

          <button onClick={handleSubmit} type="submit">
            Add
          </button>
        </form>
      </div>

      <br />
      <Footer />
    </div>
  );
}

export default BacInputData;
