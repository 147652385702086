import React from "react";
import "./AssesorOffice.scss";
import header from "../images/header-1.png";
import VissionMissionGoalCard from "./VissionMissionGoalCard";
import Footer from "./Footer";

function AgricultureOffice() {
  return (
    <div className="assesor">
      <div className="assesor_header_container">
        <img src={header} alt="" className="assesor_img" />
      </div>
      <div className="assesor_container">
        <div className="assesor_vmg_container">
          <VissionMissionGoalCard title="Vision" text="" />
          <VissionMissionGoalCard title="Mission" text="" />
          <VissionMissionGoalCard title="Goal" text="" />
        </div>
      </div>
      <br />
      <Footer />
    </div>
  );
}

export default AgricultureOffice;
