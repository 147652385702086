import React, { useRef } from "react";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import "./MayorSection.scss";
// IMAGE
import mayor_pic_thailand from "../images/mayor thailand.png";
import serbisyo_uban_sa_gugma_pic from "../images/ugban_sa_gugma.png";
import logo_nab from "../images/logo_nab.png";

function MayorSection() {
  return (
    <div className="mayorSection">
      <h1>Hon. Myrocel C. Balili</h1>
      {/* <div className="left__container">
        <motion.h1 whileHover={{ color: "green" }}>
          Hon. Myrocel C. Balili
        </motion.h1>
        <motion.p>
          {" "}
          Mayor Balili who was the only LCE awardee from Davao de Oro, and the
          entire Davao Region, was unable to personally attend the momentous
          event that took place on September 25, 2023, at Baoiyoke Sky Tower,
          Bangkok, Thailand
        </motion.p>
        <motion.p>
          {" "}
          however, her fellow awardee, Dr. Jocelyn H. Hua, DFRIEDr, Asia's Woman
          of Achievement in the Field of Education, An Outstanding College
          Administrator and Professor Award of the Year, received the award on
          her behalf, and personally handed them over to Mayor Balili yesterday,
          October 6, 2023, in the presence of Mr. Freddie Coronica, Municipal
          Administrator, and Ms. Delia Jumalon, MCTEC/MCOW Coordinator.
        </motion.p>
        <motion.p>
          {" "}
          Mayor Balili was recognized because of her outstanding leadership and
          by having productive and effective administration, which brings pride
          and honor to the people of Nabunturan, for having an exemplary leader
          who envisions a progressive community and prioritizes Nabunturanon's
          welfare.
        </motion.p>
        <motion.p className="third-p">
          The Thailand International Leadership Awards is a Thailand-based
          prestigious award-giving body that recognizes the achievements of
          extraordinary individuals in the Asia Pacific region, who have made
          significant impacts in their respective fields.
        </motion.p>
      </div> */}
      <div className="right__container">
        <img src={mayor_pic_thailand} alt="" />
        <div className="right_logo_container">
          <img src={serbisyo_uban_sa_gugma_pic} alt="" />
          <img src={logo_nab} alt="" />
        </div>
      </div>
    </div>
  );
}

export default MayorSection;
