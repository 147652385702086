import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./BacLogin.scss";
import bids_and_awards_header from "../images/bids_and_awards_header.png";
import Footer from "./Footer";
import { useStateValue } from "../StateProvider";
import { actionTypes } from "../reducer";
//firebase
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

function BacLogin() {
  const navigate = useNavigate();
  const [{ user }, dispatch] = useStateValue();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleClick = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      dispatch({
        type: actionTypes.SET_USER,
        user: true,
      });
      alert("login successful");
      navigate("/bac-input");
    } catch (error) {
      alert("Wrong Credentials");
      console.error("Error signing in", error);
    }
  };

  return (
    <div className="bacLogin">
      <img src={bids_and_awards_header} alt="" />
      <br />
      <div className="bacLogin_container">
        <div className="login-card">
          <h2>Login</h2>

          <form>
            <div className="form-group">
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit" onClick={handleClick}>
              Login
            </button>
          </form>
        </div>
      </div>

      <br />
      <Footer />
    </div>
  );
}

export default BacLogin;
