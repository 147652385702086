import React from "react";
import "./NewsCard.scss";
import { Link } from "react-router-dom";

function NewsCard({ img, title, description, date }) {
  return (
    <div className="newsCard_card">
      <img className="newsCard_card__image" src={img} alt={img} />
      <div className="newsCard_card__content">
        <h2 className="newsCard_card__title">{title}</h2>
        <p className="newsCard_card-description">{description}</p>
        <Link to="/">
          <button className="newsCard_button">Read More</button>
        </Link>
      </div>
      <span className="newsCard__date">{date}</span>
    </div>
  );
}

export default NewsCard;
