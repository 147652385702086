import React, { useState } from "react";
import { motion } from "framer-motion";
import "./SubMenu.scss";
import { Link } from "react-router-dom";
import { useEffect } from "react";

function Submenu({ item }) {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <div className="subMenu">
      <a href="#" onClick={item.subNav && showSubnav}>
        <div>
          {item.icon}
          <Link to={item.path}>
            <span>{item.title}</span>
          </Link>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </a>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <Link to={item.path} key={index} className="subNav">
              {item.icon}

              <motion.span
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                {item.title}
              </motion.span>
            </Link>
          );
        })}
    </div>
  );
}

export default Submenu;
