import React from "react";
import "./Table.scss";

function Table({ data }) {
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Fund Source</th>
            <th>Date of Notice of Authority to Debit(NADAI)</th>
            <th>Type of Program/Project</th>
            <th>Name Title of Program/Project</th>
            <th>Specific Location</th>
            <th>Mechanism/Mode of Implementation</th>
            <th>Estimated Number of Beneficiaries</th>
            <th>Amount Receive</th>
            <th>Date of Obligation</th>
            <th>Amount Obligation</th>
            <th>Amount Disbursment</th>
            <th>Estimated Period of Completion (month and year)</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.fund_source}</td>
              <td>{row.date_notice}</td>
              <td>{row.type_program}</td>
              <td>{row.name_title}</td>
              <td>{row.specific_location}</td>
              <td>{row.mechanism}</td>
              <td>{row.estimated_number}</td>
              <td>{row.amount_receive}</td>
              <td>{row.date_of_obligation}</td>
              <td>{row.amount_obligation}</td>
              <td>{row.amount_disbursment}</td>
              <td>{row.estimated_period}</td>
              <td>{row.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
