import React from "react";
import "./LocalOfficialCard.scss";

function LocalOfficialCard({ image, title, name }) {
  return (
    <div className="localOfficials__card">
      <img className="localOfficials__img" src={image} alt="" />
      <div className="localOfficials__card_content">
        <h2 className="localOfficials__card_title">{title}</h2>
        <p className="localOfficials__card_text">{name}</p>
      </div>
    </div>
  );
}

export default LocalOfficialCard;
