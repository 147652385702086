import React from "react";
import "./AssesorOffice.scss";
import header from "../images/header-1.png";
import VissionMissionGoalCard from "./VissionMissionGoalCard";
import Footer from "./Footer";

function AssesorOffice() {
  return (
    <div className="assesor">
      <div className="assesor_header_container">
        <img src={header} alt="" className="assesor_img" />
      </div>
      <div className="assesor_container">
        <div className="assesor_vmg_container">
          <VissionMissionGoalCard
            title="Vision"
            text="A dynamic and active partner in the revenue generation in sustaining socio-economic growth and development operating within the Municipality of Nabunturan."
          />
          <VissionMissionGoalCard
            title="Mission"
            text="Provide a systematic and accessible records on all Real Property assessment and to deiver basic services to the constituents of Nabunturan."
          />
          <VissionMissionGoalCard
            title="Goal"
            text="Through continued effort between taxpayers and the Local Government for the realization uplifting the living condition of Nabunturan."
          />
        </div>
      </div>
      <br />
      <Footer />
    </div>
  );
}

export default AssesorOffice;
