import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import { FaHistory } from "react-icons/fa";
import { RiGovernmentFill } from "react-icons/ri";
import { ImOffice } from "react-icons/im";
import { MdTour } from "react-icons/md";
import { ImNewspaper } from "react-icons/im";
import { BsTransparency } from "react-icons/bs";

export const SidebarData = [
  {
    title: "Home",
    path: "/",
    // icon: <FaHome />,
  },
  {
    title: "For Residents",
    path: "",
    // icon: <FaPeopleGroup />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "History",
        path: "/history",

        cName: "sub-nav",
      },
      {
        title: "Local Officials",
        path: "/local-officials",

        cName: "sub-nav",
      },
      {
        title: "LGU Offices",
        path: "/offices",
      },
    ],
  },
  {
    title: "For Visitors",
    path: "",
    // icon: <MdTour />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Festivals",
        path: "/festival",

        cName: "sub-nav",
      },
      {
        title: "Tourist Destinations",
        path: "/tourist-destination",

        cName: "sub-nav",
      },
    ],
  },
  {
    title: "News",
    path: "/news",
    // icon: <ImNewspaper />,
  },

  {
    title: "Transparency",
    path: "",
    // icon: <BsTransparency />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Financial Report",
        path: "/offices",
      },
    ],
  },
  {
    title: "Bids & Awards",
    path: "/bids-and-awards",
  },
  {
    title: "Directory",
    path: "/directory",
  },
];
