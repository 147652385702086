import React from "react";
import "./DirectoryCard.scss";

function DirectoryCard({ title, text }) {
  return (
    <div className="DirectoryCard">
      <h3 className="DirectoryCard_h3">{title}</h3>
      <span className="DirectoryCard_span">{text}</span>
    </div>
  );
}

export default DirectoryCard;
