import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import "./Sidebar.scss";
import ToggleButton from "./ToggleButton.js";
import Links from "./Links.js";
import { SidebarData } from "./SidebarData.js";
import Submenu from "./Submenu.js";
import logo_nab from "../images/logo_nab.png";

const variants = {
  open: {
    clipPath: "circle(1200px at 50px 50px",
    transition: {
      type: "spring",
      stiffness: 20,
    },
  },
  closed: {
    // clipPath: "circle(30px at 50px 50px",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

function Sidebar() {
  const [open, setOpen] = useState(false);

  return (
    <motion.div
      id="sidebar"
      className="sidebar"
      animate={open ? "open" : "closed"}
    >
      <motion.div
        style={{ display: open ? "block" : "none" }}
        className="bg"
        variants={variants}
      >
        <div className="logoSubmenuContainer">
          <img src={logo_nab} alt="" />
        </div>
        {/* <Links /> */}
        {SidebarData.map((item, index) => {
          return <Submenu item={item} key={index} />;
        })}
      </motion.div>
      <ToggleButton setOpen={setOpen} />
    </motion.div>
  );
}

export default Sidebar;
