// VideoPlaylist.js
import React, { useState } from "react";
import "./VideoPlaylist.scss";
import Footer from "./Footer";

const VideoPlaylist = ({ videosList }) => {
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);

  const handleVideoClick = (index) => {
    setSelectedVideoIndex(index);
  };

  return (
    <div className="full-container">
      <div className="container">
        <div className="main-video-container">
          <video
            src={videosList[selectedVideoIndex].video}
            loop
            controls
            className="main-video"
          ></video>
          <h3 className="main-vid-title">
            {videosList[selectedVideoIndex].title}
          </h3>
        </div>
        <div className="video-list-container">
          {videosList.map((video, index) => (
            <div
              key={index}
              className={`list ${index === selectedVideoIndex ? "active" : ""}`}
              onClick={() => handleVideoClick(index)}
            >
              <video src={video.video} className="list-video"></video>
              <h3 className="list-title">{video.title}</h3>
            </div>
          ))}
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default VideoPlaylist;
